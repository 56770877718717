import "leaflet/dist/leaflet.css";
import { GoogleMap, InfoWindowF, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useRef, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player/file";

function Map({ links, markers, onClose }) {
    const videoRef = useRef(null);

    const [dataType, setDataType] = useState(0)
    const [dataUrl, setDataUrl] = useState("");
    const [showStream, setShowStream] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null); // To store the currently clicked marker

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["geometry", "drawing", "places"],
    });

    const mapContainerStyle = {
        width: "100vw",
        height: "100vh",
    };

    const defaultCenter = {
        lat: 1.3521,
        lng: 103.8198,
    };

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker); // Show info window for clicked marker
    };

    useEffect(() => {
        if (videoRef.current) {
            const endTransition = () => {
                if (dataType === 0) {
                    setShowStream(true);
                } else if (dataType === 1) {
                    window.open(dataUrl, "_self");
                }
            };
            const element = videoRef.current._image;
            element.addEventListener("ended", endTransition);
        }
    }, [dataUrl]);

    return (
        <div className="map">
            {!showStream && isLoaded && (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={defaultCenter}
                    zoom={12}
                    options={{ fullscreenControl: false }}
                >
                    <div style={{
                        position: "absolute",
                        top: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        background: "white",
                        padding: "10px",
                        borderRadius: "4px",
                        boxShadow: "0px 2px 10px rgba(0,0,0,0.1)"
                    }}>
                        {links.map((link, index) => (
                            <Row>
                                <Col key={index}>
                                    <h5 style={{ fontSize: "20px" }}>
                                        {link.name}:{" "}
                                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                                            Visit Website
                                        </a>
                                    </h5>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    {markers.map((marker, index) => (
                        <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} onClick={() => handleMarkerClick(marker)} />
                    ))}
                    {selectedMarker && (
                        <InfoWindowF
                            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                            onCloseClick={() => setSelectedMarker(null)} // Close InfoWindow when clicked outside
                        >
                            <div>
                                <h4 className="fw-bold popupTitle">{selectedMarker.name}</h4> {/* Title of the InfoWindow */}
                                <p>{selectedMarker.address}</p>
                                <p>{selectedMarker.postal_code}</p>
                                <p>
                                    <a href={selectedMarker.url} target="_blank" rel="noopener noreferrer">
                                        Visit Website
                                    </a>
                                </p>
                            </div>
                        </InfoWindowF>
                    )}
                </GoogleMap>
            )}
            {dataUrl !== "" && (
                <ReactPlayer 
                    url={dataUrl} 
                    playing={true} 
                    controls={false} 
                    muted={true} 
                    width="100%" 
                    height="100%" 
                    playsinline={true} 
                    config={{hlsVersion: "1.5.13"}}
                />
            )}
            <img className="close" src={require("./images/close.png")} alt="close" onClick={onClose}></img>
        </div>
    );
}

export default Map;
