import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
    // React StrictMode renders components twice on dev server
    // https://react.dev/reference/react/StrictMode
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>
);
