import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import MoonLoader from "react-spinners/MoonLoader";
import Swal from "sweetalert2";
import Map from "./Map";

function App() {
    const videoRef = useRef(null);
    const photoRef = useRef(null);

    const [stream, setStream] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [links, setLinks] = useState([]);
    const [markers, setMarkers] = useState([]);

    const getVideo = () => {
        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        if (!showMap) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: {
                        facingMode: "environment",
                        width: { ideal: 960 },
                        height: { ideal: 720 },
                    },
                })
                .then((stream) => {
                    let video = videoRef.current;
                    video.srcObject = stream;

                    var playPromise = video.play();

                    if (playPromise !== undefined) {
                        playPromise
                            .then((_) => {
                                // Automatic playback started!
                                // Show playing UI.
                                setStream(stream);
                            })
                            .catch((error) => {
                                // Auto-play was prevented
                                // Show paused UI.
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const takePhoto = () => {
        let video = videoRef.current;
        let photo = photoRef.current;

        const width = video.videoWidth;
        const height = video.videoHeight;

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext("2d");
        ctx.drawImage(video, 0, 0, width, height);

        photo.toBlob((blob) => {
            uploadPhoto(blob);
        });
        ctx.clearRect(0, 0, photo.width, photo.height);
    };

    const uploadPhoto = (blob) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", blob);

        axios.post("https://visionlink-backend-dev.fly.dev/api/search", formData)
            .then((res) => {
                setLoading(false);
                const results = res.data.map((location) => ({
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude),
                    name: location.name,
                    address: location.address,
                    postal_code: location.postal_code,
                    url: location.url,
                    link_type: location.link_type,
                    linktype_name: location.linktype_name,
                }));
                const markers = results.filter((location) => location.linktype_name === "Map Location");
                const links = results.filter((location) => location.linktype_name === "URL");
                console.log(results)
                console.log(markers)
                console.log(links)
                setLinks(links);
                setMarkers(markers);

                if (links.length === 1 && markers.length === 0) {
                    window.open(links[0].url, "_self");
                } else {
                    setShowMap(true);
                }
            })
            .catch(function (error) {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    Swal.fire({
                        title: "No match found!",
                        html: "Try again with a clearer image.<br>" +
                              "Make sure the landmark is centered and well-lit.<br>" +
                              "Try to fill most of the frame with the landmark.",
                        icon: "info",
                        confirmButtonText: "OK",
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        html: "Something went wrong.<br>" + error.message,
                        icon: "error",
                        confirmButtonText: "Close",
                    });
                }
            });
    };

    const closeMap = () => {
        setShowMap(false);
    };

    const initElements = () => {
        const fileInput = document.getElementById("upload-btn");

        fileInput.addEventListener("click", (e) => {
            fileInput.addEventListener("change", (e) => {
                const f = e.target.files[0];
                uploadPhoto(f);
            }, { once: true });
        });
    };

    const handleVisibilityChange = () => {
        if (!document.hidden) {
            getVideo();
        }
    };

    const handleFocus = () => {
        getVideo();
    };

    useEffect(() => {
        getVideo();
        if (!showMap) {
            initElements();
        }

        // Add event listeners for visibility change and focus
        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("focus", handleFocus);

        // Cleanup function
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("focus", handleFocus);
        };
    }, [showMap]);

    return (
        <div className="App">
            {!showMap ? (
                <div className="camera">
                    <video ref={videoRef} playsInline={true}></video>
                    <canvas ref={photoRef}></canvas>
                    <input type="file" id="upload-btn" hidden accept="image/*" />
                    <div className="control">
                        <img className="scanButton" src={require("./images/scan.png")} alt="button" onClick={takePhoto}></img>
                        <label htmlFor="upload-btn" className="uploadLabel">
                            <img className="uploadButton" src={require("./images/upload.png")} alt="button"></img>
                        </label>
                    </div>
                    <img id="uploadedImg" alt="uploaded" hidden />
                </div>
            ) : (
                <Map links={links} markers={markers} onClose={closeMap}></Map>
            )}
            <div className={"loading " + (loading ? "display" : "")}>
                <MoonLoader color="#ffffff" size={50} loading={loading}></MoonLoader>
            </div>
        </div>
    );
}

export default App;
